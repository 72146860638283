<template>
  <v-card color="surface" class="rounded-lg">
    <section class="pa-6">
      <h1 class="text-h5">Dados Pessoais</h1>
    </section>

    <v-divider />

    <section class="pa-6">
      <v-list class="pa-0 transparent" nav>
        <v-list-item class="accent">
          <v-list-item-action-text class="pr-4">Nome</v-list-item-action-text>

          <v-list-item-title>
            {{ user.firstName }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="accent">
          <v-list-item-action-text class="pr-4">
            Sobrenome
          </v-list-item-action-text>

          <v-list-item-title>{{ user.lastName }}</v-list-item-title>
        </v-list-item>

        <v-list-item class="accent">
          <v-list-item-action-text class="pr-4">E-mail</v-list-item-action-text>

          <v-list-item-title>{{ user.email }}</v-list-item-title>
        </v-list-item>

        <v-list-item class="accent">
          <v-list-item-action-text class="pr-4"
            >Telefone</v-list-item-action-text
          >

          <v-list-item-title
            >+{{ user.ddi }} {{ user.phone }}</v-list-item-title
          >
        </v-list-item>

        <v-list-item class="accent">
          <v-list-item-action-text class="pr-4"
            >Criação</v-list-item-action-text
          >

          <v-list-item-title>
            {{ formatDate(user.createdAt) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </section>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils";

export default {
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    formatDate,
  },
};
</script>

<style>
</style>